<template>
  <b-card>
    <b-button size="sm" variant="success" @click="exportExcel"
      >ExportExcel
    </b-button>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay :show="show" rounded="sm">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- View -->
            <template #cell(view)="data">
              <div
                v-show="data.item.deviceId === 'Master Admin' ? false : true"
              >
                <b-button
                  size="sm"
                  variant="warning"
                  class="btn mx-50"
                  @click="Info(data.item.userId)"
                >
                  <feather-icon icon="EyeIcon" /> View
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  class="btn mx-50"
                  @click="Delete(data.item)"
                >
                  <feather-icon icon="Trash2Icon" /> Delete
                </b-button>
              </div>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <!-- pagination -->
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay,
} from "bootstrap-vue";
import API from "@/views/API/condb.js";
import axios from "axios";
import _ from "lodash";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BOverlay,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      show: true,
      fields: [
        {
          key: "sequence",
          label: "ID",
          sortable: true,
        },
        { key: "username", label: "User Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "registerDate", label: "Register Date Time", sortable: true },
        { key: "view", label: "", sortable: false },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  async created() {
    this.getData();
    if (
      _.isEmpty(localStorage.getItem("access_token")) ||
      _.isEmpty(localStorage.getItem("access_user"))
    ) {
      localStorage.removeItem("access_user");
      localStorage.removeItem("access_token");
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    async Delete(value) {
      console.log(value);
      const { userId, username } = value;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { access_token } = await this.access_token();
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const url = `${API}delPass`;
          const data = {
            userId: userId,
            userName: username,
          };
          const res = await axios.delete(url, data, head);
          console.log(res.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Your file has been deleted.",
              icon: "Trash2Icon",
              variant: "success",
            },
          });
        }
      });
    },
    async Info(value) {
      this.$router.push({
        name: "smartIdDataInfo",
        params: { id: value },
      });
    },
    async getData() {
      const { access_token } = await this.access_token();
      const { userId } = JSON.parse(localStorage.getItem("access_user"));
      const url = `${API}user`;
      const userIdData = {
        userId: userId,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.post(url, userIdData, head);
      this.totalRows = res.data.message.data.length;
      this.items = res.data.message.data.map((el, index) => {
        return {
          ...el,
          sequence: index + 1,
          registerDate: new Date(el.registerDate).toLocaleString("th-TH"),
        };
      });
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}token`, "", {
          headers: {
            Authorization:
              localStorage.getItem("access_token") &&
              JSON.parse(localStorage.getItem("access_token")),
          },
        })
      ).data.message;
    },
    exportExcel() {
      saveExcel({
        data: this.items,
        fileName: "Smart Id Data",
        columns: [
          {
            title: "Smart Id Data",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "ID",
                field: "sequence",
              },
              {
                title: "Username",
                field: "username",
              },
              {
                title: "Email",
                field: "email",
              },
              {
                title: "Register Date Time",
                field: "registerDate",
              },
            ],
          },
        ],
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
